import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MainLogo from '../MainLogo.png';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link
} from 'react-router-dom';

import { Image, Col, Row, Container} from 'react-bootstrap';


import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";
import Footer from './Footer';

import chatIcon from '../coffeeIcon.jpg';
import plainSconeIcon from '../PlainScone.png';
import buildingWeb from '../buildingWeb.jpg';
import logoTilt from '../logoTilt.jpg';
import deliveryIcon from '../deliveryIcon.jpg';
import pickupIcon from '../pickupIcon.jpg';

import * as firebase from 'firebase';


class PrintOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      orderId: this.props.match.params.id,
      orderSheet: null
    }
  }


  componentWillMount() {

  }

  componentDidMount() {
    firebase.database().ref(`orderSheets/${this.state.orderId}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        this.setState({orderSheet: snapshot.val()})
      }
    })
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }

  }

  onGetTotalAmount(items) {
    let result = Object.values(items).reduce((a, b) => a + b.amount, 0)
    return result
  }

  render() {
    if (this.state.isLoading || !this.state.orderSheet) {
      return <div></div>
    }
    let orderSheet = this.state.orderSheet
    let orderSheetArr = Object.values(this.state.orderSheet['orders'])
    return (
      <Grid container justify="center">
        <div className="page">
          { orderSheetArr.map((order, index) => {
            if (index % 4 === 0) {
              return (
                <div className="direction-row-div">
                <div className="subpage">
                 <div className="direction-column-div">
                  { orderSheetArr[index + 2] &&
                    <div className="one-fourth">
                    <table className="sheet-table" id="table" >
                      <thead>
                          <tr><th id="th-title" colspan="25">택배주문서</th></tr>
                      </thead>
                      <tbody>
                          <tr><td id="td" colspan="3">주문자 정보</td><td id="td" colspan="12">스콘</td><td id="td" colspan="6">휘낭시에</td><td id="td" colspan="3">롤</td><td id="td" colspan="2">크림번</td><td id="td" colspan="3">쿠키</td><td id="td" rowspan="2">총 수량</td></tr>
                          <tr>
                            <th id="th">No.</th>
                            <th id="th">주문자</th>
                            <th id="th">수령자</th>
                            <th id="th">플레인</th>
                            <th id="th">크럼블</th>
                            <th id="th">시나몬 스콘</th>
                            <th id="th">레몬 스콘</th>
                            <th id="th">에그스콘</th>
                            <th id="th">블루베리에그스콘</th>
                            <th id="th">초코송이에그</th>
                            <th id="th">콘스콘</th>
                            <th id="th">애플시나몬</th>
                            <th id="th">누네띠네 스콘</th>
                            <th id="th">라즈베리에그</th>
                            <th id="th">메이플피칸</th>
                            <th id="th">플레인휘낭시에</th>
                            <th id="th">카라멜휘낭시에</th>
                            <th id="th">솔티트초코휘낭시에</th>
                            <th id="th">쑥 크럼블 휘낭시에</th>
                            <th id="th">갈릭크림치즈휘낭시에</th>
                            <th id="th">황치즈 휘낭시에</th>
                            <th id="th">시나몬 롤</th>
                            <th id="th">초코 롤</th>
                            <th id="th">카라멜피칸 롤</th>
                            <th id="th">라즈베리 크림번</th>
                            <th id="th">옥수수 크림번</th>
                            <th id="th">황치즈 쿠키</th>
                            <th id="th">위나 쿠키</th>
                            <th id="th">초코마약 쿠키</th>
                          </tr>
                          <tr>
                            <td id="td-big-font">{index + 3 + 4}</td>
                            <td id="td-1">{orderSheetArr[index + 2]['buyerName']}</td>
                            <td id="td-1">{orderSheetArr[index + 2]['addressTo']['receiver']}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['ENXex55N4'] ? orderSheetArr[index + 2]['items']['ENXex55N4'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['SW8g84Fi7'] ? orderSheetArr[index + 2]['items']['SW8g84Fi7'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['JY2XRab1j'] ? orderSheetArr[index + 2]['items']['JY2XRab1j'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['EgE29AfJ98'] ? orderSheetArr[index + 2]['items']['EgE29AfJ98'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['NYkkc1ryd'] ? orderSheetArr[index + 2]['items']['NYkkc1ryd'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['N4dDse24Sa'] ? orderSheetArr[index + 2]['items']['N4dDse24Sa'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['1r2QwejR40'] ? orderSheetArr[index + 2]['items']['1r2QwejR40'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['Po-QQGXmU'] ? orderSheetArr[index + 2]['items']['Po-QQGXmU'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['Nd345dFq20'] ? orderSheetArr[index + 2]['items']['Nd345dFq20'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['A8MaVd-JU'] ? orderSheetArr[index + 2]['items']['A8MaVd-JU'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['N31Dqd40Fe'] ? orderSheetArr[index + 2]['items']['N31Dqd40Fe'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['9svZrVSuC'] ? orderSheetArr[index + 2]['items']['9svZrVSuC'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['Ag23Df1t6b'] ? orderSheetArr[index + 2]['items']['Ag23Df1t6b'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['A93DsadR3h'] ? orderSheetArr[index + 2]['items']['A93DsadR3h'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['A4edFs23gW'] ? orderSheetArr[index + 2]['items']['A4edFs23gW'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['A5tSd09e2JH'] ? orderSheetArr[index + 2]['items']['A5tSd09e2JH'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['Adf356daA0'] ? orderSheetArr[index + 2]['items']['Adf356daA0'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['Ade8d3FgA2'] ? orderSheetArr[index + 2]['items']['Ade8d3FgA2'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['1Dyy5MmBuz2'] ? orderSheetArr[index + 2]['items']['1Dyy5MmBuz2'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['1AxrE_CQ1N'] ? orderSheetArr[index + 2]['items']['1AxrE_CQ1N'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['1Dzd38Df90'] ? orderSheetArr[index + 2]['items']['1Dzd38Df90'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['3FE3Qdf9ab'] ? orderSheetArr[index + 2]['items']['3FE3Qdf9ab'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['3rEf5F09Fq'] ? orderSheetArr[index + 2]['items']['3rEf5F09Fq'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['X4wIGoivO'] ? orderSheetArr[index + 2]['items']['X4wIGoivO'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['LmBisFaKZ'] ? orderSheetArr[index + 2]['items']['LmBisFaKZ'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['items']['OD6_3hJKi'] ? orderSheetArr[index + 2]['items']['OD6_3hJKi'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 2]['totalAmount']}</td>

                          </tr>
                      </tbody>
                    </table>
                    { orderSheetArr[index + 2]['additionalItems'] &&
                      <table className="sheet-table" id="additional-table" >
                        <tbody>
                            <tr><td id="td" colspan="10">상품</td><td id="td" rowspan="2">총 수량</td></tr>
                            <tr>
                              <th id="th">딸기 콩포트</th>
                              <th id="th">라즈베리잼</th>
                              <th id="th">클로티드 크림</th>
                              <th id="th">아쌈</th>
                              <th id="th">스콘믹스</th>
                              <th id="th">머그컵330ml (그린)</th>
                              <th id="th">머그컵330ml (오렌지)</th>
                              <th id="th">머그컵200ml (그린)</th>
                              <th id="th">머그컵200ml (오렌지)</th>
                              <th id="th">우드티코스터</th>
                            </tr>
                            <tr>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['2E3gXD-i1'] ? orderSheetArr[index + 2]['additionalItems']['2E3gXD-i1'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['2e41Df9Ad0'] ? orderSheetArr[index + 2]['additionalItems']['2e41Df9Ad0'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['eXuir_o5k'] ? orderSheetArr[index + 2]['additionalItems']['eXuir_o5k'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['hXo2RQU_w'] ? orderSheetArr[index + 2]['additionalItems']['hXo2RQU_w'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['ACkU0pIH9'] ? orderSheetArr[index + 2]['additionalItems']['ACkU0pIH9'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['BgzeeCnZd2'] ? orderSheetArr[index + 2]['additionalItems']['BgzeeCnZd2'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['BkMuBT9_rM'] ? orderSheetArr[index + 2]['additionalItems']['BkMuBT9_rM'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['DSZYSKZqr'] ? orderSheetArr[index + 2]['additionalItems']['DSZYSKZqr'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['D0vZqaBEdW'] ? orderSheetArr[index + 2]['additionalItems']['D0vZqaBEdW'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 2]['additionalItems']['EXXCAMXKn'] ? orderSheetArr[index + 2]['additionalItems']['EXXCAMXKn'].amount : ''}</td>
                              <td id="td-big-font">{this.onGetTotalAmount(orderSheetArr[index + 2]['additionalItems'])}</td>
                            </tr>
                        </tbody>
                      </table>
                    }
                    </div>
                  }

                  { orderSheetArr[index + 3] &&
                    <div  className="one-fourth">
                    <table className="sheet-table" id="table" >
                      <thead>
                          <tr><th id="th-title" colspan="25">택배주문서</th></tr>
                      </thead>
                      <tbody>
                          <tr><td id="td" colspan="3">주문자 정보</td><td id="td" colspan="12">스콘</td><td id="td" colspan="6">휘낭시에</td><td id="td" colspan="3">롤</td><td id="td" colspan="2">크림번</td><td id="td" colspan="3">쿠키</td><td id="td" rowspan="2">총 수량</td></tr>
                          <tr>
                            <th id="th">No.</th>
                            <th id="th">주문자</th>
                            <th id="th">수령자</th>
                            <th id="th">플레인</th>
                            <th id="th">크럼블</th>
                            <th id="th">시나몬 스콘</th>
                            <th id="th">레몬 스콘</th>
                            <th id="th">에그스콘</th>
                            <th id="th">블루베리에그스콘</th>
                            <th id="th">초코송이에그</th>
                            <th id="th">콘스콘</th>
                            <th id="th">애플시나몬</th>
                            <th id="th">누네띠네 스콘</th>
                            <th id="th">라즈베리에그</th>
                            <th id="th">메이플피칸</th>
                            <th id="th">플레인휘낭시에</th>
                            <th id="th">카라멜휘낭시에</th>
                            <th id="th">솔티트초코휘낭시에</th>
                            <th id="th">쑥 크럼블 휘낭시에</th>
                            <th id="th">갈릭크림치즈휘낭시에</th>
                            <th id="th">황치즈 휘낭시에</th>
                            <th id="th">시나몬 롤</th>
                            <th id="th">초코 롤</th>
                            <th id="th">카라멜피칸 롤</th>
                            <th id="th">라즈베리 크림번</th>
                            <th id="th">옥수수 크림번</th>
                            <th id="th">황치즈 쿠키</th>
                            <th id="th">위나 쿠키</th>
                            <th id="th">초코마약 쿠키</th>
                          </tr>
                          <tr>
                            <td id="td-big-font">{index + 4 + 4}</td>
                            <td id="td-1">{orderSheetArr[index + 3]['buyerName']}</td>
                            <td id="td-1">{orderSheetArr[index + 3]['addressTo']['receiver']}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['ENXex55N4'] ? orderSheetArr[index + 3]['items']['ENXex55N4'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['SW8g84Fi7'] ? orderSheetArr[index + 3]['items']['SW8g84Fi7'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['JY2XRab1j'] ? orderSheetArr[index + 3]['items']['JY2XRab1j'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['EgE29AfJ98'] ? orderSheetArr[index + 3]['items']['EgE29AfJ98'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['NYkkc1ryd'] ? orderSheetArr[index + 3]['items']['NYkkc1ryd'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['N4dDse24Sa'] ? orderSheetArr[index + 3]['items']['N4dDse24Sa'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['1r2QwejR40'] ? orderSheetArr[index + 3]['items']['1r2QwejR40'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['Po-QQGXmU'] ? orderSheetArr[index + 3]['items']['Po-QQGXmU'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['Nd345dFq20'] ? orderSheetArr[index + 3]['items']['Nd345dFq20'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['A8MaVd-JU'] ? orderSheetArr[index + 3]['items']['A8MaVd-JU'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['N31Dqd40Fe'] ? orderSheetArr[index + 3]['items']['N31Dqd40Fe'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['9svZrVSuC'] ? orderSheetArr[index + 3]['items']['9svZrVSuC'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['Ag23Df1t6b'] ? orderSheetArr[index + 3]['items']['Ag23Df1t6b'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['A93DsadR3h'] ? orderSheetArr[index + 3]['items']['A93DsadR3h'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['A4edFs23gW'] ? orderSheetArr[index + 3]['items']['A4edFs23gW'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['A5tSd09e2JH'] ? orderSheetArr[index + 3]['items']['A5tSd09e2JH'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['Adf356daA0'] ? orderSheetArr[index + 3]['items']['Adf356daA0'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['Ade8d3FgA2'] ? orderSheetArr[index + 3]['items']['Ade8d3FgA2'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['1Dyy5MmBuz2'] ? orderSheetArr[index + 3]['items']['1Dyy5MmBuz2'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['1AxrE_CQ1N'] ? orderSheetArr[index + 3]['items']['1AxrE_CQ1N'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['1Dzd38Df90'] ? orderSheetArr[index + 3]['items']['1Dzd38Df90'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['3FE3Qdf9ab'] ? orderSheetArr[index + 3]['items']['3FE3Qdf9ab'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['3rEf5F09Fq'] ? orderSheetArr[index + 3]['items']['3rEf5F09Fq'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['X4wIGoivO'] ? orderSheetArr[index + 3]['items']['X4wIGoivO'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['LmBisFaKZ'] ? orderSheetArr[index + 3]['items']['LmBisFaKZ'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['items']['OD6_3hJKi'] ? orderSheetArr[index + 3]['items']['OD6_3hJKi'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 3]['totalAmount']}</td>
                          </tr>
                      </tbody>
                    </table>
                    { orderSheetArr[index + 3]['additionalItems'] &&
                      <table className="sheet-table" id="additional-table" >
                        <tbody>
                            <tr><td id="td" colspan="10">상품</td><td id="td" rowspan="2">총 수량</td></tr>
                            <tr>
                              <th id="th">딸기 콩포트</th>
                              <th id="th">라즈베리잼</th>
                              <th id="th">클로티드 크림</th>
                              <th id="th">아쌈</th>
                              <th id="th">스콘믹스</th>
                              <th id="th">머그컵330ml (그린)</th>
                              <th id="th">머그컵330ml (오렌지)</th>
                              <th id="th">머그컵200ml (그린)</th>
                              <th id="th">머그컵200ml (오렌지)</th>
                              <th id="th">우드티코스터</th>
                            </tr>
                            <tr>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['2E3gXD-i1'] ? orderSheetArr[index + 3]['additionalItems']['2E3gXD-i1'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['2e41Df9Ad0'] ? orderSheetArr[index + 3]['additionalItems']['2e41Df9Ad0'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['eXuir_o5k'] ? orderSheetArr[index + 3]['additionalItems']['eXuir_o5k'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['hXo2RQU_w'] ? orderSheetArr[index + 3]['additionalItems']['hXo2RQU_w'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['ACkU0pIH9'] ? orderSheetArr[index + 3]['additionalItems']['ACkU0pIH9'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['BgzeeCnZd2'] ? orderSheetArr[index + 3]['additionalItems']['BgzeeCnZd2'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['BkMuBT9_rM'] ? orderSheetArr[index + 3]['additionalItems']['BkMuBT9_rM'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['DSZYSKZqr'] ? orderSheetArr[index + 3]['additionalItems']['DSZYSKZqr'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['D0vZqaBEdW'] ? orderSheetArr[index + 3]['additionalItems']['D0vZqaBEdW'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 3]['additionalItems']['EXXCAMXKn'] ? orderSheetArr[index + 3]['additionalItems']['EXXCAMXKn'].amount : ''}</td>
                              <td id="td-big-font">{this.onGetTotalAmount(orderSheetArr[index + 3]['additionalItems'])}</td>
                            </tr>
                        </tbody>
                      </table>
                    }
                    </div>
                  }
                  </div>
                  </div>
                  <div className="subpage">
                  <div className="direction-column-div">
                  { orderSheetArr[index] &&
                    <div className="one-fourth">
                    <table className="sheet-table" id="table" >
                      <thead>
                          <tr><th id="th-title" colspan="25">택배주문서</th></tr>
                      </thead>
                      <tbody>
                          <tr><td id="td" colspan="3">주문자 정보</td><td id="td" colspan="12">스콘</td><td id="td" colspan="6">휘낭시에</td><td id="td" colspan="3">롤</td><td id="td" colspan="2">크림번</td><td id="td" colspan="3">쿠키</td><td id="td" rowspan="2">총 수량</td></tr>
                          <tr>
                            <th id="th">No.</th>
                            <th id="th">주문자</th>
                            <th id="th">수령자</th>
                            <th id="th">플레인</th>
                            <th id="th">크럼블</th>
                            <th id="th">시나몬 스콘</th>
                            <th id="th">레몬 스콘</th>
                            <th id="th">에그스콘</th>
                            <th id="th">블루베리에그스콘</th>
                            <th id="th">초코송이에그</th>
                            <th id="th">콘스콘</th>
                            <th id="th">애플시나몬</th>
                            <th id="th">누네띠네 스콘</th>
                            <th id="th">라즈베리에그</th>
                            <th id="th">메이플피칸</th>
                            <th id="th">플레인휘낭시에</th>
                            <th id="th">카라멜휘낭시에</th>
                            <th id="th">솔티트초코휘낭시에</th>
                            <th id="th">쑥 크럼블 휘낭시에</th>
                            <th id="th">갈릭크림치즈휘낭시에</th>
                            <th id="th">황치즈 휘낭시에</th>
                            <th id="th">시나몬 롤</th>
                            <th id="th">초코 롤</th>
                            <th id="th">카라멜피칸 롤</th>
                            <th id="th">라즈베리 크림번</th>
                            <th id="th">옥수수 크림번</th>
                            <th id="th">황치즈 쿠키</th>
                            <th id="th">위나 쿠키</th>
                            <th id="th">초코마약 쿠키</th>
                          </tr>
                          <tr>
                            <td id="td-big-font">{index + 1 + 4}</td>
                            <td id="td-1">{orderSheetArr[index]['buyerName']}</td>
                            <td id="td-1">{orderSheetArr[index]['addressTo']['receiver']}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['ENXex55N4'] ? orderSheetArr[index]['items']['ENXex55N4'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['SW8g84Fi7'] ? orderSheetArr[index]['items']['SW8g84Fi7'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['JY2XRab1j'] ? orderSheetArr[index]['items']['JY2XRab1j'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['EgE29AfJ98'] ? orderSheetArr[index]['items']['EgE29AfJ98'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['NYkkc1ryd'] ? orderSheetArr[index]['items']['NYkkc1ryd'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['N4dDse24Sa'] ? orderSheetArr[index]['items']['N4dDse24Sa'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['1r2QwejR40'] ? orderSheetArr[index]['items']['1r2QwejR40'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['Po-QQGXmU'] ? orderSheetArr[index]['items']['Po-QQGXmU'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['Nd345dFq20'] ? orderSheetArr[index]['items']['Nd345dFq20'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['A8MaVd-JU'] ? orderSheetArr[index]['items']['A8MaVd-JU'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['N31Dqd40Fe'] ? orderSheetArr[index]['items']['N31Dqd40Fe'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['9svZrVSuC'] ? orderSheetArr[index]['items']['9svZrVSuC'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['Ag23Df1t6b'] ? orderSheetArr[index]['items']['Ag23Df1t6b'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['A93DsadR3h'] ? orderSheetArr[index]['items']['A93DsadR3h'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['A4edFs23gW'] ? orderSheetArr[index]['items']['A4edFs23gW'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['A5tSd09e2JH'] ? orderSheetArr[index]['items']['A5tSd09e2JH'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['Adf356daA0'] ? orderSheetArr[index]['items']['Adf356daA0'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['Ade8d3FgA2'] ? orderSheetArr[index]['items']['Ade8d3FgA2'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['1Dyy5MmBuz2'] ? orderSheetArr[index]['items']['1Dyy5MmBuz2'].amount : ''}</td>

                            <td id="td-big-font">{orderSheetArr[index]['items']['1AxrE_CQ1N'] ? orderSheetArr[index]['items']['1AxrE_CQ1N'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['1Dzd38Df90'] ? orderSheetArr[index]['items']['1Dzd38Df90'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['3FE3Qdf9ab'] ? orderSheetArr[index]['items']['3FE3Qdf9ab'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['3rEf5F09Fq'] ? orderSheetArr[index]['items']['3rEf5F09Fq'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['X4wIGoivO'] ? orderSheetArr[index]['items']['X4wIGoivO'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['LmBisFaKZ'] ? orderSheetArr[index]['items']['LmBisFaKZ'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['items']['OD6_3hJKi'] ? orderSheetArr[index]['items']['OD6_3hJKi'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index]['totalAmount']}</td>

                          </tr>
                      </tbody>
                    </table>
                    { orderSheetArr[index]['additionalItems'] &&
                      <table className="sheet-table" id="additional-table" >
                        <tbody>
                            <tr><td id="td" colspan="10">상품</td><td id="td" rowspan="2">총 수량</td></tr>
                            <tr>
                              <th id="th">딸기 콩포트</th>
                              <th id="th">라즈베리잼</th>
                              <th id="th">클로티드 크림</th>
                              <th id="th">아쌈</th>
                              <th id="th">스콘믹스</th>
                              <th id="th">머그컵330ml (그린)</th>
                              <th id="th">머그컵330ml (오렌지)</th>
                              <th id="th">머그컵200ml (그린)</th>
                              <th id="th">머그컵200ml (오렌지)</th>
                              <th id="th">우드티코스터</th>
                            </tr>
                            <tr>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['2E3gXD-i1'] ? orderSheetArr[index]['additionalItems']['2E3gXD-i1'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['2e41Df9Ad0'] ? orderSheetArr[index]['additionalItems']['2e41Df9Ad0'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['eXuir_o5k'] ? orderSheetArr[index]['additionalItems']['eXuir_o5k'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['hXo2RQU_w'] ? orderSheetArr[index]['additionalItems']['hXo2RQU_w'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['ACkU0pIH9'] ? orderSheetArr[index]['additionalItems']['ACkU0pIH9'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['BgzeeCnZd2'] ? orderSheetArr[index]['additionalItems']['BgzeeCnZd2'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['BkMuBT9_rM'] ? orderSheetArr[index]['additionalItems']['BkMuBT9_rM'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['DSZYSKZqr'] ? orderSheetArr[index]['additionalItems']['DSZYSKZqr'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['D0vZqaBEdW'] ? orderSheetArr[index]['additionalItems']['D0vZqaBEdW'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index]['additionalItems']['EXXCAMXKn'] ? orderSheetArr[index]['additionalItems']['EXXCAMXKn'].amount : ''}</td>
                              <td id="td-big-font">{this.onGetTotalAmount(orderSheetArr[index]['additionalItems'])}</td>
                            </tr>
                        </tbody>
                      </table>
                    }
                    </div>
                  }

                  { orderSheetArr[index + 1] &&
                    <div  className="one-fourth">
                    <table className="sheet-table" id="table" >
                      <thead>
                          <tr><th id="th-title" colspan="25">택배주문서</th></tr>
                      </thead>
                      <tbody>
                          <tr><td id="td" colspan="3">주문자 정보</td><td id="td" colspan="12">스콘</td><td id="td" colspan="6">휘낭시에</td><td id="td" colspan="3">롤</td><td id="td" colspan="2">크림번</td><td id="td" colspan="3">쿠키</td><td id="td" rowspan="2">총 수량</td></tr>
                          <tr>
                            <th id="th">No.</th>
                            <th id="th">주문자</th>
                            <th id="th">수령자</th>
                            <th id="th">플레인</th>
                            <th id="th">크럼블</th>
                            <th id="th">시나몬 스콘</th>
                            <th id="th">레몬 스콘</th>
                            <th id="th">에그스콘</th>
                            <th id="th">블루베리에그스콘</th>
                            <th id="th">초코송이에그</th>
                            <th id="th">콘스콘</th>
                            <th id="th">애플시나몬</th>
                            <th id="th">누네띠네 스콘</th>
                            <th id="th">라즈베리에그</th>
                            <th id="th">메이플피칸</th>
                            <th id="th">플레인휘낭시에</th>
                            <th id="th">카라멜휘낭시에</th>
                            <th id="th">솔티트초코휘낭시에</th>
                            <th id="th">쑥 크럼블 휘낭시에</th>
                            <th id="th">갈릭크림치즈휘낭시에</th>
                            <th id="th">황치즈 휘낭시에</th>
                            <th id="th">시나몬 롤</th>
                            <th id="th">초코 롤</th>
                            <th id="th">카라멜피칸 롤</th>
                            <th id="th">라즈베리 크림번</th>
                            <th id="th">옥수수 크림번</th>
                            <th id="th">황치즈 쿠키</th>
                            <th id="th">위나 쿠키</th>
                            <th id="th">초코마약 쿠키</th>
                          </tr>
                          <tr>
                            <td id="td-big-font">{index + 2 + 4}</td>
                            <td id="td-1">{orderSheetArr[index + 1]['buyerName']}</td>
                            <td id="td-1">{orderSheetArr[index + 1]['addressTo']['receiver']}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['ENXex55N4'] ? orderSheetArr[index + 1]['items']['ENXex55N4'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['SW8g84Fi7'] ? orderSheetArr[index + 1]['items']['SW8g84Fi7'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['JY2XRab1j'] ? orderSheetArr[index + 1]['items']['JY2XRab1j'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['EgE29AfJ98'] ? orderSheetArr[index + 1]['items']['EgE29AfJ98'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['NYkkc1ryd'] ? orderSheetArr[index + 1]['items']['NYkkc1ryd'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['N4dDse24Sa'] ? orderSheetArr[index + 1]['items']['N4dDse24Sa'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['1r2QwejR40'] ? orderSheetArr[index + 1]['items']['1r2QwejR40'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['Po-QQGXmU'] ? orderSheetArr[index + 1]['items']['Po-QQGXmU'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['Nd345dFq20'] ? orderSheetArr[index + 1]['items']['Nd345dFq20'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['A8MaVd-JU'] ? orderSheetArr[index + 1]['items']['A8MaVd-JU'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['N31Dqd40Fe'] ? orderSheetArr[index + 1]['items']['N31Dqd40Fe'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['9svZrVSuC'] ? orderSheetArr[index + 1]['items']['9svZrVSuC'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['Ag23Df1t6b'] ? orderSheetArr[index + 1]['items']['Ag23Df1t6b'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['A93DsadR3h'] ? orderSheetArr[index + 1]['items']['A93DsadR3h'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['A4edFs23gW'] ? orderSheetArr[index + 1]['items']['A4edFs23gW'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['A5tSd09e2JH'] ? orderSheetArr[index + 1]['items']['A5tSd09e2JH'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['Adf356daA0'] ? orderSheetArr[index + 1]['items']['Adf356daA0'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['Ade8d3FgA2'] ? orderSheetArr[index + 1]['items']['Ade8d3FgA2'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['1Dyy5MmBuz2'] ? orderSheetArr[index + 1]['items']['1Dyy5MmBuz2'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['1AxrE_CQ1N'] ? orderSheetArr[index + 1]['items']['1AxrE_CQ1N'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['1Dzd38Df90'] ? orderSheetArr[index + 1]['items']['1Dzd38Df90'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['3FE3Qdf9ab'] ? orderSheetArr[index + 1]['items']['3FE3Qdf9ab'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['3rEf5F09Fq'] ? orderSheetArr[index + 1]['items']['3rEf5F09Fq'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['X4wIGoivO'] ? orderSheetArr[index + 1]['items']['X4wIGoivO'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['LmBisFaKZ'] ? orderSheetArr[index + 1]['items']['LmBisFaKZ'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['items']['OD6_3hJKi'] ? orderSheetArr[index + 1]['items']['OD6_3hJKi'].amount : ''}</td>
                            <td id="td-big-font">{orderSheetArr[index + 1]['totalAmount']}</td>

                          </tr>
                      </tbody>
                    </table>
                    { orderSheetArr[index + 1]['additionalItems'] &&
                      <table className="sheet-table" id="additional-table" >
                        <tbody>
                            <tr><td id="td" colspan="10">상품</td><td id="td" rowspan="2">총 수량</td></tr>
                            <tr>
                              <th id="th">딸기 콩포트</th>
                              <th id="th">라즈베리잼</th>
                              <th id="th">클로티드 크림</th>
                              <th id="th">아쌈</th>
                              <th id="th">스콘믹스</th>
                              <th id="th">머그컵330ml (그린)</th>
                              <th id="th">머그컵330ml (오렌지)</th>
                              <th id="th">머그컵200ml (그린)</th>
                              <th id="th">머그컵200ml (오렌지)</th>
                              <th id="th">우드티코스터</th>
                            </tr>
                            <tr>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['2E3gXD-i1'] ? orderSheetArr[index + 1]['additionalItems']['2E3gXD-i1'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['2e41Df9Ad0'] ? orderSheetArr[index + 1]['additionalItems']['2e41Df9Ad0'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['eXuir_o5k'] ? orderSheetArr[index + 1]['additionalItems']['eXuir_o5k'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['hXo2RQU_w'] ? orderSheetArr[index + 1]['additionalItems']['hXo2RQU_w'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['ACkU0pIH9'] ? orderSheetArr[index + 1]['additionalItems']['ACkU0pIH9'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['BgzeeCnZd2'] ? orderSheetArr[index + 1]['additionalItems']['BgzeeCnZd2'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['BkMuBT9_rM'] ? orderSheetArr[index + 1]['additionalItems']['BkMuBT9_rM'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['DSZYSKZqr'] ? orderSheetArr[index + 1]['additionalItems']['DSZYSKZqr'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['D0vZqaBEdW'] ? orderSheetArr[index + 1]['additionalItems']['D0vZqaBEdW'].amount : ''}</td>
                              <td id="td-big-font">{orderSheetArr[index + 1]['additionalItems']['EXXCAMXKn'] ? orderSheetArr[index + 1]['additionalItems']['EXXCAMXKn'].amount : ''}</td>
                              <td id="td-big-font">{this.onGetTotalAmount(orderSheetArr[index + 1]['additionalItems'])}</td>
                            </tr>
                        </tbody>
                      </table>
                    }
                    </div>
                  }
                  </div>
                  </div>



                </div>
              )
            }

          })

          }
        </div>
      </Grid>
    )
  }
}

export default PrintOrders;
